import firebase from "firebase/app";
import 'firebase/firestore';
import 'firebase/analytics';


const firebaseConfig = {
    apiKey: "AIzaSyA7pB9kkVtBTFxNlg8oyg_FgMPz9b5NROQ",
    authDomain: "personalportfolio-c183a.firebaseapp.com",
    projectId: "personalportfolio-c183a",
    storageBucket: "personalportfolio-c183a.appspot.com",
    messagingSenderId: "1012835337264",
    appId: "1:1012835337264:web:52a2ec067ea16ea00359f5",
    measurementId: "G-68KZKMFRDC"
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore;